@font-face {
font-family: '__SuisseIntlRegular_6cb88e';
src: url(/_next/static/media/6fa1afdfb81c9ae2.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__SuisseIntlRegular_Fallback_6cb88e';src: local("Arial");ascent-override: 95.68%;descent-override: 30.18%;line-gap-override: 0.00%;size-adjust: 103.05%
}.__className_6cb88e {font-family: '__SuisseIntlRegular_6cb88e', '__SuisseIntlRegular_Fallback_6cb88e';font-weight: 400
}.__variable_6cb88e {--font-suisse-intl-regular: '__SuisseIntlRegular_6cb88e', '__SuisseIntlRegular_Fallback_6cb88e'
}

@font-face {
font-family: '__SuisseIntlBook_0ce286';
src: url(/_next/static/media/411784ae08dd9d58.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__SuisseIntlBook_Fallback_0ce286';src: local("Arial");ascent-override: 94.87%;descent-override: 29.92%;line-gap-override: 0.00%;size-adjust: 103.93%
}.__className_0ce286 {font-family: '__SuisseIntlBook_0ce286', '__SuisseIntlBook_Fallback_0ce286';font-weight: 400
}.__variable_0ce286 {--font-suisse-intl-book: '__SuisseIntlBook_0ce286', '__SuisseIntlBook_Fallback_0ce286'
}

@font-face {
font-family: '__SuisseIntlMono_dd204a';
src: url(/_next/static/media/4c017ab9f28baa7b.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__SuisseIntlMono_Fallback_dd204a';src: local("Arial");ascent-override: 69.65%;descent-override: 21.97%;line-gap-override: 0.00%;size-adjust: 141.57%
}.__className_dd204a {font-family: '__SuisseIntlMono_dd204a', '__SuisseIntlMono_Fallback_dd204a';font-weight: 400
}.__variable_dd204a {--font-suisse-intl-mono: '__SuisseIntlMono_dd204a', '__SuisseIntlMono_Fallback_dd204a'
}

@font-face {
font-family: '__SuisseWorksRegular_383a3b';
src: url(/_next/static/media/b40dc325ca9c2dca.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__SuisseWorksRegular_Fallback_383a3b';src: local("Arial");ascent-override: 90.53%;descent-override: 28.56%;line-gap-override: 0.00%;size-adjust: 108.91%
}.__className_383a3b {font-family: '__SuisseWorksRegular_383a3b', '__SuisseWorksRegular_Fallback_383a3b';font-weight: 400
}.__variable_383a3b {--font-suisse-works-regular: '__SuisseWorksRegular_383a3b', '__SuisseWorksRegular_Fallback_383a3b'
}

@font-face {
font-family: '__SuisseWorksMedium_ef7a6c';
src: url(/_next/static/media/c3b63d3ed3f13788.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__SuisseWorksMedium_Fallback_ef7a6c';src: local("Arial");ascent-override: 88.79%;descent-override: 28.00%;line-gap-override: 0.00%;size-adjust: 111.05%
}.__className_ef7a6c {font-family: '__SuisseWorksMedium_ef7a6c', '__SuisseWorksMedium_Fallback_ef7a6c';font-weight: 400
}.__variable_ef7a6c {--font-suisse-works-medium: '__SuisseWorksMedium_ef7a6c', '__SuisseWorksMedium_Fallback_ef7a6c'
}

